import React from "react";
import { Helmet } from "react-helmet";

export default () => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta http-equiv="content-encoding" content="gzip; charset=UTF-8" />
    <title>The Financial Support System for Youth Sports.</title>
    <meta property="og:title" content="Privacy Policy | LiftRocket" />
    <meta name="twitter:title" content="Privacy Policy | LiftRocket" />

    <link rel="canonical" href="https://www.liftrocket.com/privacypolicy/" />
    <meta
      property="og:url"
      content="https://www.liftrocket.com/privacypolicy/"
    />
    <meta
      name="twitter:url"
      content="https://www.liftrocket.com/privacypolicy/"
    />

    <meta
      property="og:description"
      content="We keep kids playing. The LiftRocket financing and spend management system helps families pay for registration, equipment, travel, training and more. Every dollar in your account earns interest and every dollar you spend earns 2% Scholarship Rewards for your team. Our Lift credit line is the budget-friendly backstop every sports family deserves. Do you run a league, team, sports camp, or training center, or have a kid who plays?"
    />
    <meta
      property="og:description"
      content="We keep kids playing. The LiftRocket financing and spend management system helps families pay for registration, equipment, travel, training and more. Every dollar in your account earns interest and every dollar you spend earns 2% Scholarship Rewards for your team. Our Lift credit line is the budget-friendly backstop every sports family deserves. Do you run a league, team, sports camp, or training center, or have a kid who plays?"
    />
    <meta
      name="twitter:description"
      content="We keep kids playing. The LiftRocket financing and spend management system helps families pay for registration, equipment, travel, training and more. Every dollar in your account earns interest and every dollar you spend earns 2% Scholarship Rewards for your team. Our Lift credit line is the budget-friendly backstop every sports family deserves. Do you run a league, team, sports camp, or training center, or have a kid who plays?"
    />

    <meta property="fb:app_id" content="383520185612995" />
    <meta property="og:type" content="article" />

    {/*
    <meta name='description'         content="A Lift lets you meet life's needs without a bank, paperwork, or credit scores. LiftRocket allows you to borrow money when you need it the most." />
    <meta property='og:description'  content="A Lift lets you meet life's needs without a bank, paperwork, or credit scores. LiftRocket allows you to borrow money when you need it the most." />
    <meta name='twitter:description' content="A Lift lets you meet life's needs without a bank, paperwork, or credit scores. LiftRocket allows you to borrow money when you need it the most." />
    */}

    <link
      rel="icon"
      href="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
    <meta
      property="og:image"
      content="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
    <meta
      name="twitter:image"
      content="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
  </Helmet>
);
